import React, { Component } from 'react';
import {
    Header,
    Layout,
    PreviewList,
    Logo
  } from '../../components';
import './Home.scss';

class Home extends Component {
    render() {
        return (
            <Layout 
                logo={<Logo />}
                header={<Header />}
                list={<PreviewList />}
            />
        )
    }
}

export default Home;