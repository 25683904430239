import React, { Component } from 'react';
import Axios from 'axios';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import './IncidentList.scss';

const columns = [
    {
        label: 'City',
        dataKey: 'cityName',
        width: 400,
        cellRenderer: ({cellData}) => (
            <div className={'state'}>{`${cellData}`}</div>
        )
    }, 
    {
        label:'Date',
        dataKey: 'dateText',
        width: 400,
        cellRenderer: ({cellData}) => (
            <div className={'date'}>{`${cellData}`}</div>
        )
    },
    {
        label:'Links',
        dataKey: 'links',
        width: 400,
        cellRenderer: ({cellData}) => (
            <div className={'links'}>
                {
                    cellData.map(item => <a className={'link'} target="_blank" rel="noopener noreferrer" href={item}>{'Link '}</a>) || <span>No Links</span>
                }
            </div>
        )
    }
]

class IncidentList extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            loading: true
        };
    }

    async componentDidMount() {
        this.setState({ loading: true }, async () => {
            Axios.get('https://raw.githubusercontent.com/2020PB/police-brutality/data_build/all-locations.json')
              .then(result => this.setState({
                loading: false,
                data: this.columnizeData(this.digestData(result.data.data))
              }));
          });
    }

    getList = (data) => {
        return (
            <AutoSizer disableHeight>
                {({width}) => (
                    <Table
                        width={width}
                        height={400}
                        headerHeight={30}
                        headerClassName={'.header-column'}
                        rowHeight={100}
                        rowCount={data.length || 0}
                        rowGetter={({ index }) => data[index]}
                        noRowsRenderer={noRowsRenderer}
                        rowClassName={rowClassName}>
                            {mapColumns(columns)}
                    </Table>
                )}
            </AutoSizer>
        )
    }

    columnizeData = (data) => {
        console.log(data)
        const newData = data.map((item, index) => {
            console.log(item)
            if(item.state === this.props.stateName) {
                return {
                    name: item.name,
                    cityName: item.city,
                    dateText: item.date_text,
                    date: item.date,
                    links: item.links
                }
            }
        })
        return newData.sort(this.propComparator('date'))
    }

    digestData = (data) => {
        let extractedData = []
        data.map(item => {
            if(item.state === this.props.stateName) {
                extractedData.push(item)
            }
        })
        return extractedData;
    }

    propComparator = (propName) => (a, b) => a[propName].toLowerCase() === b[propName].toLowerCase() ? 0 : a[propName].toLowerCase() > b[propName].toLowerCase() ? -1 : 1

    render() {
        const { data, loading } = this.state;
        return (
            <div>
                {loading ? <span>{'Loading...'}</span> : this.getList(data)}
            </div>
        )
    }
}

export const percentRenderer = ({cellData}) => (`${cellData * 100} %`)

export const noRowsRenderer = () => (
    <div className={'no-rows'}>No Data</div>
)

export const rowClassName = ({index}) => {
    if (index < 0) {
        return 'header-row';
      } else {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
      }
}

export const mapColumns = (columns) => {
    return columns.map((column, index) => (
            <Column 
                key={index}
                className={'default-column'} 
                {...column}/> 
    ))
}

export default IncidentList;