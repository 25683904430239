import React from 'react';
import './Layout.scss';

const Layout = ({ logo, header, list }) => (
    <div className={'layout-container'}>
        <div className={'logo-item'}>
            {logo}
        </div>
        <div className={'header-item'}>
            {header}
        </div>
        <div className={'list-item'}>
            {list}
        </div>
    </div>
)

export default Layout;