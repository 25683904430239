import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import State from './screens/State/State';
import Home from './screens/Home/Home';
import Incident from './screens/Incident/Incident';

import './App.scss';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/:state" component={State}/>
          <Route exact path="/:state/:incident" component={Incident}/>
          <Route path="/" component={Home}/>
        </Switch>
      </Router>
    )
  }
}

export default App;
