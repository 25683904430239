import React, { Component } from 'react';
import {
    Layout,
    Logo,
    IncidentList
  } from '../../components';
import './State.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class State extends Component {

    getHeader = (stateName) => {
        return (
            <div className={'state-heading'}>
                <Link to={'/'} className={'state-back'}><FontAwesomeIcon icon={faArrowLeft} /></Link>
                <span className={'state-name'}>{stateName}</span>
                <div className={'filler'}></div>
            </div>
        )
    }
    render() {
        const stateName = (this.props.location.stateName) ? this.props.location.stateName : this.props.match.url.toString().substr(1);
        return (
            <Layout 
                logo={<Logo />}
                header={this.getHeader(stateName)}
                list={<IncidentList stateName={stateName}/>}
            />
        )
    }
}

export default State;