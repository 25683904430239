import React, { Component } from 'react';
import Axios from 'axios';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import './PreviewList.scss';
import { Link } from 'react-router-dom';

const columns = [
    {
        label: 'State',
        dataKey: 'state',
        width: 500,
        cellRenderer: ({cellData}) => (
            <div className={'state'}>
                <Link to={{
                    pathname: `/${cellData}`,
                    stateName: cellData
                }}>
                    {`${cellData}`}
                </Link>
            </div>
        )
    }, 
    {
        label:'Incidents',
        dataKey: 'value',
        width: 500,
        cellRenderer: ({cellData}) => (
            <div className={'value'}>{`${cellData}`}</div>
        )
    }
]

class PreviewList extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            loading: true
        };
    }

    async componentDidMount() {
        this.setState({ loading: true }, async () => {
            Axios.get('https://raw.githubusercontent.com/2020PB/police-brutality/data_build/all-locations.json')
              .then(result => this.setState({
                loading: false,
                data: this.columnizeData(this.digestData(result.data.data))
              }));
          });
    }

    defaultRowClick = ({ event, index, rowData}) => {
        console.log('click')
    }

    getList = (data) => {
        return (
            <AutoSizer disableHeight>
                {({width}) => (
                    <Table
                        width={width}
                        height={400}
                        headerHeight={30}
                        headerClassName={'.header-column'}
                        rowHeight={80}
                        rowCount={data.length || 0}
                        rowGetter={({ index }) => data[index]}
                        noRowsRenderer={noRowsRenderer}
                        rowClassName={rowClassName}
                        onRowClick={this.defaultRowClick}>
                            {mapColumns(columns)}
                    </Table>
                )}
            </AutoSizer>
        )
    }

    columnizeData = (data) => {
        const keys = Object.keys(data)
        const newData = keys.map((item, index) => {

            return {
                state: item,
                value: data[item]
            }
        })
        return newData.sort(this.propComparator('state'))
    }

    digestData = (data) => {
        console.log(data)
        let countData = {}
        data.map(item => {
            if(!countData[item.state]) {
                countData[item.state] = 0;
            }
            countData[item.state] += 1;
            return item;
        })
        return countData;
    }

    propComparator = (propName) => (a, b) => a[propName].toLowerCase() === b[propName].toLowerCase() ? 0 : a[propName].toLowerCase() < b[propName].toLowerCase() ? -1 : 1

    render() {
        const { data, loading } = this.state;
        return (
            <div>
                {loading ? <span>{'Loading...'}</span> : this.getList(data)}
            </div>
        )
    }
}

export const percentRenderer = ({cellData}) => (`${cellData * 100} %`)

export const noRowsRenderer = () => (
    <div className={'no-rows'}>No Data</div>
)

export const rowClassName = ({index}) => {
    if (index < 0) {
        return 'header-row';
      } else {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
      }
}

export const mapColumns = (columns) => {
    return columns.map((column, index) => (
            <Column 
                key={index}
                className={'default-column'} 
                {...column}/> 
    ))
}

export default PreviewList;