import React from 'react';
import './Header.scss';

const title = '#DefundThePolice';
const apiHyperLink = <a target="_blank" rel="noopener noreferrer" href="https://github.com/2020PB/police-brutality">{'Engineers'}</a>;
const redditHyperLink = <a target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/r/2020PoliceBrutality/">{'/r/2020PoliceBrutality'}</a>;

const description = <span>{'This site showcases the number of reported, unique instances of police brutality since George Floyd\'s death. The data is updated everyday and organized by '}{redditHyperLink} and these {apiHyperLink}</span>;

const blmHyperLink = <a target="_blank" rel="noopener noreferrer" href="https://blacklivesmatter.com/">{'BLM Homepage'}</a>;

const Header = () => (
    <div className={'header-container'}>
        <div className={'header-title'}>
            {title}
        </div>
        <div className={'header-description'}>
            {description}
        </div>
        <div className={'header-links'}>
            <div className={'blm-link'}>{blmHyperLink}</div>
        </div>
    </div>
)

export default Header;